import {
  getCombinationInfo,
  getListProductsInfo,
  getSKU,
  generateCombinationName,
} from '../helpers/product.js';
import ENV from '../env.js';
import FbConversion from './fb-conversion';
const $get = require('lodash.get');
const Config = require('~config/default.env').default;
const GoogleTagManager = {
  payload: {},
  eventName: '',
  addPaymentInfo(dataPaymentInfo) {
    const extractProducts = getListProductsInfo(dataPaymentInfo.carts.products);
    const contentsId = extractProducts.contentsId.every((Id) => Id === '')
      ? ''
      : extractProducts.contentsId.join(', ');
    this.payload = {
      event: 'addPaymentInfo',
      add_payment_info_data: {
        Payment_Type: dataPaymentInfo.payment_type,
        Id_Payment_Method: dataPaymentInfo.id_payment_method,
        Price: dataPaymentInfo.total_price,
        Content_Category: dataPaymentInfo.payment_type,
        Product_Name: extractProducts.productsName,
        Quantity: extractProducts.totalQuantity,
        Brands: extractProducts.brands,
        Amount_Of_Purchase: dataPaymentInfo.total_price,
        Category_Parent: extractProducts.categoriesParent,
        Category_Child: extractProducts.categoriesChild,
        Category_GrandChild: extractProducts.categoriesGrandChild,
        Category: extractProducts.categoriesParent,
        Contents: extractProducts.contents,
        Content_Ids: extractProducts.contentsId,
      },
    };
    window.dataLayer.push(this.payload);
    FbConversion.fbTracking({
      name: 'AddPaymentInfo',
      data: {
        content_category: extractProducts.categoriesParent.join(', '),
        content_subcategory1: extractProducts.categoriesChild.join(', '),
        content_ids: contentsId,
        contents: extractProducts.contents,
        content_name: extractProducts.productsName.join(', '),
        currency: 'IDR',
        value: parseFloat(dataPaymentInfo.total_price),
        content_type: 'product',
      },
    });
    this.eventName = 'addPaymentInfo';
    return this;
  },
  addToCart(product, qty) {
    const combinationInfo = getCombinationInfo(product);
    const contentId = $get(
      product,
      'default_combination.tracker_combination_name',
      '',
    );
    let price;
    if (product?.default_combination?.price_after_discount) {
      price = $get(product, 'default_combination.price_after_discount', 0);
    } else if (product?.combination?.price_after_discount) {
      price = $get(product, 'combination.price_after_discount', 0);
    } else {
      price = 0;
    }

    this.payload = {
      event: 'addToCart',
      cart_data: {
        Brand: $get(product, 'brand.name', ''),
        Category_Parent: $get(product, 'categories[0].name', ''),
        Category_Child: $get(product, 'categories[1].name', ''),
        Category_GrandChild: $get(product, 'categories[2].name', ''),
        Category: $get(product, 'default_category.name', ''),
        Id: product.id,
        Product_Name: product.name,
        Price: price,
        Combination_Name: combinationInfo.combinationName,
        Attribute_Slug: combinationInfo.attributeSlug,
        Quantity: qty,
        Content_Ids: contentId.toLowerCase(),
        Contents: [{ id: contentId.toLowerCase(), quantity: qty }],
        SKU: getSKU(product),
      },
      ga4Cart: {
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            quantity: parseInt(qty),
            price: price,
            item_brand: $get(product, 'brand.name', ''),
            item_category: $get(product, 'default_category.name', ''),
            item_category2: $get(product, 'categories[0].name', ''),
            item_category3: $get(product, 'categories[1].name', ''),
            item_category4: $get(product, 'categories[1].name', ''),
            item_variant: combinationInfo.combinationName,
          },
        ],
      },
    };

    window.dataLayer.push(this.payload);
    FbConversion.fbTracking({
      name: 'AddToCart',
      data: {
        value: parseFloat(price),
        content_name: product.name,
        currency: 'IDR',
        content_ids: contentId.toLowerCase(),
        content_type: 'product',
        content_category: $get(product, 'categories[0].name', ''),
        content_subcategory1: $get(product, 'categories[1].name', ''),
        // content_subcategory2: $get(product, 'categories[2].name', ''),
        // sku: getSKU(product),
        brand: $get(product, 'brand.name', ''),
        contents: [{ id: contentId.toLowerCase(), quantity: qty }],
        // combination_name: combinationInfo.combinationName,
        product_id: product.id,
      },
    });

    this.eventName = 'addToCart';
    return this;
  },
  addToWishlist(product) {
    const combinationInfo = getCombinationInfo(product);
    const contentId = $get(
      product,
      'default_combination.tracker_combination_name',
      '',
    );
    this.payload = {
      event: 'addToWishlist',
      wishlist_data: {
        Brand: $get(product, 'brand.name', ''),
        Category_Parent: $get(product, 'categories[0].name', ''),
        Category_Child: $get(product, 'categories[1].name', ''),
        Category_GrandChild: $get(product, 'categories[2].name', ''),
        Id: product.id,
        Product_Name: product.name,
        Price: $get(product, 'default_combination.price_after_discount', ''),
        Combination_Name: combinationInfo.combinationName,
        Content_Ids: contentId.toLowerCase(),
        Contents: [
          {
            id: contentId.toLowerCase(),
            quantity: 1,
          },
        ],
        Quantity: 1,
      },
    };
    window.dataLayer.push(this.payload);
    FbConversion.fbTracking({
      name: 'AddToWishlist',
      data: {
        content_ids: contentId.toLowerCase(),
        content_type: 'product',
        content_name: product.name,
        content_category: $get(product, 'default_category.name', ''),
        content_subcategory1: $get(product, 'categories[0].name', ''),
        content_subcategory2: $get(product, 'categories[1].name', ''),
        value: $get(product, 'default_combination.price_after_discount', ''),
        currency: 'IDR',
        brand: $get(product, 'brand.name', ''),
        quantity: 1,
        contents: [
          {
            id: contentId.toLowerCase(),
            quantity: 1,
          },
        ],
      },
    });
    this.eventName = 'addToWishlist';
    return this;
  },
  initiateCheckout(data) {
    const extractProducts = getListProductsInfo(data.products);
    const price =
      'total_amount_after_discount' in data
        ? data.total_amount_after_discount
        : 0;

    const contentsId = extractProducts.contentsId.every((Id) => Id === '')
      ? ''
      : extractProducts.contentsId.join(', ');
    this.payload = {
      event: 'initiateCheckout',
      initiate_checkout_data: {
        Content_Ids: extractProducts.contentsId,
        Cart_Id: data.cart_id,
        Shipping_Type: data.shipping_type,
        Price: price,
        Category_Parent: extractProducts.categoriesParent,
        Category_Child: extractProducts.categoriesChild,
        Category_GrandChild: extractProducts.categoriesGrandChild,
        Content_Name: extractProducts.productsName,
        Contents: extractProducts.contents,
        Quantity: extractProducts.totalQuantity,
        Brand: extractProducts.brands,
      },
    };

    window.dataLayer.push(this.payload);
    FbConversion.fbTracking({
      name: 'InitiateCheckout',
      data: {
        content_ids: contentsId,
        currency: 'IDR',
        value: parseFloat(price),
        content_name: extractProducts.productsName.join(', '),
        contents: extractProducts.contents,
        content_type: 'product',
        content_category: extractProducts.categoriesParent.join(', '),
        content_subcategory1: extractProducts.categoriesChild.join(', '),
        content_subcategory2: extractProducts.categoriesGrandChild.join(', '),
        brand: extractProducts.brands.join(', '),
        quantity: extractProducts.totalQuantity,
      },
    });

    this.eventName = 'initiateCheckout';
    return this;
  },
  viewContent(product) {
    const combinationInfo = getCombinationInfo(product);
    const contentId = $get(
      product,
      'default_combination.tracker_combination_name',
      '',
    );
    this.payload = {
      event: 'viewContent',
      view_content_data: {
        Brand: $get(product, 'brand.name', ''),
        Category_Parent: $get(product, 'categories[0].name', ''),
        Category_Child: $get(product, 'categories[1].name', ''),
        Category_GrandChild: $get(product, 'categories[2].name', ''),
        Category: $get(product, 'default_category.name', ''),
        Id: product.id,
        Product_Name: product.name,
        Price: $get(product, 'default_combination.price_after_discount', ''),
        Combination_Name: combinationInfo.combinationName,
        Attribute_Slug: combinationInfo.attributeSlug,
        SKU: getSKU(product),
        Contents_Ids: contentId.toLowerCase(),
        Quantity: 1,
        Contents: [
          {
            id: contentId.toLowerCase(),
            quantity: 1,
          },
        ],
      },
      ga4ViewContent: {
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_brand: $get(product, 'brand.name', ''),
            item_category: $get(product, 'default_category.name', ''),
            item_category2: $get(product, 'categories[0].name', ''),
            item_category3: $get(product, 'categories[1].name', ''),
            item_category4: $get(product, 'categories[2].name', ''),
            item_variant: combinationInfo.combinationName,
            price: $get(
              product,
              'default_combination.price_after_discount',
              '',
            ),
          },
        ],
      },
    };

    window.dataLayer.push(this.payload);
    FbConversion.fbTracking({
      name: 'ViewContent',
      data: {
        content_ids: contentId.toLowerCase(),
        content_type: 'product',
        content_name: product.name,
        content_brand: $get(product, 'brand.name', ''),
        content_category: $get(product, 'default_category.name', ''),
        content_subcategory1: $get(product, 'categories[0].name', ''),
        content_subcategory2: $get(product, 'categories[1].name', ''),
        content_subcategory3: $get(product, 'categories[2].name', ''),
        value: $get(product, 'default_combination.price_after_discount', ''),
        currency: 'IDR',
        search_keyword: product.keyword,
        search_result: product.name,
        contents: [
          {
            id: contentId.toLowerCase(),
            quantity: 1,
          },
        ],
        // quantity: 1,
        // sku: getSKU(product),
      },
    });
    this.eventName = 'viewContent';
    return this;
  },
  trackProductInsider(product) {
    let image;
    if (product.default_combination.images?.length) {
      image = product.default_combination.images.find((img) => img.is_cover);
      if (!image) {
        image = product.default_combination.images[0];
      }
    }
    let productUrl = Config.LULLA_WEB_URL;
    if (product.default_category?.slug) {
      productUrl += `/${$get(product, 'default_category.slug')}`;
    }
    if (product.slug) {
      productUrl += `/${$get(product, 'slug')}`;
    }
    const product_data = {
      id: $get(product, 'id')?.toString(),
      name: $get(product, 'name'),
      taxonomy: [
        ...($get(product, 'categories') || []).map((category) => category.name),
      ],
      currency: 'IDR',
      unit_price: $get(product, 'default_combination.price'),
      unit_sale_price: $get(
        product,
        'default_combination.price_after_discount',
      ),
      url: productUrl,
      stock: $get(product, 'default_combination.stock'),
      size: $get(product, 'default_combination.attributes.size.name') || '-',
      product_image_url: image?.url || '',
      custom: {
        shade:
          $get(product, 'default_combination.attributes.shade.name') || '-',
        variant:
          $get(product, 'default_combination.attributes.variant.name') || '-',
        non_specify:
          $get(product, 'default_combination.attributes.non_specify.name') ||
          '-',
        combination_id: $get(product, 'default_combination._id'),
        brand_name: $get(product, 'brand.name'),
      },
    };
    window.dataLayer.push({
      event: 'productDetail',
      product_data,
    });
  },
  viewBrands(brand) {
    window.dataLayer.push({
      event: 'brand_page',
      brand_page: brand.name,
    });
  },
  search(payload) {
    this.payload = {
      event: 'search',
      search_data: {
        search_keyword: payload.text,
        search_result: payload.SearchResult,
      },
    };

    window.dataLayer.push(this.payload);
    FbConversion.fbTracking({
      name: 'Search',
      data: {
        search_keyword: payload.text,
        search_result: payload.SearchResult,
      },
    });
    this.eventName = 'search';
    return this;
  },
  purchaseConfirmaton(data) {
    const transaction_obj = {
      order_id: data._id,
      currency: 'IDR',
      total: data.total_paid,
      shipping_cost: data.total_shipping || 0,
      delivery: {
        country: 'ID',
        city: data.shipping?.delivery_address?.city?.name || '',
        district: data.shipping?.delivery_address?.district?.name || '',
      },
      payment_type: data.payment_method?.name || '',
      line_items: data.products.map((pr) => {
        const categories = [];
        if (pr.category_parent) {
          categories.push(pr.category_parent);
        }
        if (pr.category_child) {
          categories.push(pr.category_child);
        }
        if (pr.category_grandchild) {
          categories.push(pr.category_grandchild);
        }
        const combinationName = { attributes: {} };
        if (pr.combination.size) {
          combinationName.attributes.size = pr.combination.size;
        }
        if (pr.combination.shade) {
          combinationName.attributes.shade = pr.combination.shade;
        }
        if (pr.combination.variant) {
          combinationName.attributes.variant = pr.combination.variant;
        }
        if (pr.combination.non_specify) {
          combinationName.attributes.non_specify = pr.combination.non_specify;
        }
        return {
          product: {
            id: `${pr.id}_${generateCombinationName(combinationName, true)}`,
            groupcode: pr.id.toString(),
            name: pr.name,
            taxonomy: categories,
            currency: 'IDR',
            unit_price: pr.unit_price,
            unit_sale_price: pr.unit_price_after_discount,
            url: pr.url || '',
            size: pr.combination.size?.name || '',
            product_image_url: pr.images?.[0]?.url || '',
            custom: {
              variant: pr.combination.variant?.name || '',
              shade: pr.combination.shade?.name || '',
              non_specify: pr.combination.non_specify?.name || '',
              combination_id: pr.combination.id || '',
              brand_name: pr.brand.name || '',
              total_redeem_point: data.total_redeem_point || 0,
              voucher_applied_code: data.voucher_applied?.code || '',
            },
          },
          quantity: pr.quantity,
          subtotal: pr.price_after_discount,
        };
      }),
    };
    window.dataLayer.push({
      event: 'purchaseConfirmation',
      transaction_object: transaction_obj,
    });
  },
  purchase(data) {
    let ga4ProductItems = [];
    if (data?.cartDetail?.products) {
      const extractProducts = getListProductsInfo(data.cartDetail.products);
      const contentsId = extractProducts.contentsId.every((Id) => Id === '')
        ? ''
        : extractProducts.contentsId.join(', ');
      ga4ProductItems = data.products.map((res) => {
        return {
          item_id: res.id,
          item_name: res.name,
          affiliation: 'Lilla Online Beauty Store',
          item_brand: res.brand.name,
          item_category:
            'default_category' in res && res.default_category.name
              ? res.default_category.name
              : '',
          quantity: res.quantity,
          price: res.price_after_discount,
        };
      });

      for (let i = 0; i < ga4ProductItems.length; i++) {
        ga4ProductItems[i].item_variant = extractProducts.combinationsName[i];
        ga4ProductItems[i].item_category2 = extractProducts.categoriesParent[i];
        ga4ProductItems[i].item_category3 = extractProducts.categoriesChild[i];
        ga4ProductItems[i].item_category4 =
          extractProducts.categoriesGrandChild[i];
      }

      window.dataLayer.push({
        event: 'purchase',
        purchase_data: {
          Brand: extractProducts.brands,
          Quantity: extractProducts.totalQuantity,
          Product_Name: extractProducts.productsName,
          Product_Id: extractProducts.productsId,
          Payment_Method: $get(data, 'payment_method.name', ''),
          Amount_Of_Purchase: $get(data, 'total_paid', ''),
          Order_Id: $get(data, '_id', ''),
          Combination_Name: extractProducts.combinationsName,
          Content_Ids: extractProducts.contentsId,
          Contents: extractProducts.contents,
          Category_Parent: extractProducts.categoriesParent,
          Category_Child: extractProducts.categoriesChild,
          Category_GrandChild: extractProducts.categoriesGrandChild,
          currency: 'IDR',
          value: $get(data, 'total_paid', ''),
          transaction_id: $get(data, '_id', ''),
        },
        ga4Purchase: {
          affiliation: 'Lilla Online Beauty Store',
          items: ga4ProductItems,
        },
      });

      FbConversion.fbTracking({
        name: 'Purchase',
        data: {
          value: $get(data, 'total_paid', ''),
          order_id: $get(data, '_id', ''),
          // coupon: '',
          currency: 'IDR',
          brand: extractProducts.brands.join(', '),
          contents: extractProducts.contents,
          content_ids: contentsId,
          content_type: 'product',
          content_name: extractProducts.productsName.join(', '),
          content_brand: extractProducts.brands.join(', '),
          // content_order_id: $get(data, '_id', ''),
          content_product_id: extractProducts.productsId.join(', '),
          // content_quantity: extractProducts.totalQuantity,
          // content_payment_method: $get(data, 'payment_method.name', ''),
          content_category: extractProducts.categoriesParent.join(', '),
          content_subcategory1: extractProducts.categoriesChild.join(', '),
          // content_subcategory2: extractProducts.categoriesGrandChild,
          // content_combination_name: extractProducts.combinationsName,
        },
      });
    }

    this.eventName = 'purchase';
    return this;
  },

  userTrack(user) {
    if (user) {
      window.dataLayer.push({
        event: 'userTrack',
        user_id_mongo: user._id,
        user_data: {
          userIDMongo: user._id,
          userEmail: user.email,
          userName: user.user_name,
          userFirstname: user.first_name,
          userLastname: user.last_name,
          userBirth: user.date_of_birth,
          userGender: user.gender,
          userPhone: user.phone_no_e164,
          socoPoints: user.lilla_points?.count || '',
          userLevel: user.lilla_level?.current?.stage || '',
          isVerifiedEmail: user.is_verified_email || false,
          isVerifiedPhone: user.is_verified_phone || false,
          beautyCreatedAt: user.beauty_created_at ? true : false,
          country: user.country?.name || '',
          district: user.district?.name || '',
          city: user.city?.name || '',
          province: user.province?.name || '',
        },
      });
    }
  },
  pageTracking(name) {
    window.dataLayer.push({
      event: 'pageNameReady',
      page_name: name,
    });
  },
  bannerClick: () => {},
  trackCartInsider(cart) {
    const cart_data = {
      currency: 'IDR',
      total: cart.total_amount_after_discount,
      line_items: (cart.products || []).map((product) => {
        let productUrl = Config.LULLA_WEB_URL;
        if (product.detail?.slug) {
          productUrl += `/${$get(product, 'default_category.slug')}`;
        }
        if (product.slug) {
          productUrl += `/${$get(product, 'slug')}`;
        }
        return {
          product: {
            id: $get(product, 'id')?.toString(),
            name: $get(product, 'detail.name'),
            taxonomy: [
              ...($get(product, 'categories') || []).map(
                (category) => category.name,
              ),
            ],
            currency: 'IDR',
            unit_price: $get(product, 'detail.combination.price'),
            unit_sale_price: $get(
              product,
              'detail.combination.price_after_discount',
            ),
            url: productUrl,
            stock: $get(product, 'stock'),
            size:
              $get(product, 'detail.combination.attributes.size.name') || '-',
            product_image_url: $get(product, 'detail.image.url'),
            custom: {
              shade:
                $get(product, 'detail.combination.attributes.shade.name') ||
                '-',
              variant:
                $get(product, 'detail.combination.attributes.variant.name') ||
                '-',
              non_specify:
                $get(
                  product,
                  'detail.combination.attributes.non_specify.name',
                ) || '-',
              combination_id: $get(product, 'combination_id'),
              brand_name: $get(product, 'detail.brand.name'),
            },
          },
          quantity: product.quantity,
          subtotal: $get(product, 'detail.combination.total_price'),
        };
      }),
    };

    window.dataLayer.push({
      event: 'cartChange',
      cart_data,
    });
  },
  debug() {
    if (ENV.debug) {
      if (this.payload && Object.keys(this.payload).length) {
        console.log(`-----Event ${this.eventName} Data-----`);
        for (const key in this.payload) {
          console.log(`${key} => ${this.payload[key]}`);
          if (typeof this.payload[key] === 'object') {
            for (const keyChild in this.payload[key]) {
              console.log(`${keyChild} => ${this.payload[key][keyChild]}`);
            }
          }
        }
        console.log('-----EndDebug-----');
      }
    }
    return this;
  },
};

export default GoogleTagManager;
